import axios from 'axios';

function getUrlExtension(url: string): string {
    const urlWithoutParams = url.split('?')[0] ?? '';
    const match = /\.[0-9a-z]+$/i.exec(urlWithoutParams);
    return match ? match[0].slice(1) : '';
}

export async function immediatelyDownloadFile(url: string, filename: string): Promise<void> {
    const extension = getUrlExtension(url);

    const response = await axios.get<Blob>(url, {
        responseType: 'blob',
    });
    const binaryData = response.data;

    if (binaryData != null) {
        const blob = new Blob([binaryData]);
        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.${extension}`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}
